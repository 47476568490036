import { Component } from '@angular/core';
import { Event as RouterEvent, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute, RouteConfigLoadEnd, RouteConfigLoadStart } from '@angular/router';
import { NgProgress } from 'ngx-progressbar';
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  providers: [NgxSpinnerService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app works!';

  constructor(public router: Router, private route: ActivatedRoute, public ngProgress: NgProgress, private spinner: NgxSpinnerService, private titleService: Title) {
    var asyncLoadCount = 0;
    router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;
          this.spinner.show();
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
          this.spinner.hide();
        }
      }
    );
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  changeIcon() {
    this.favIcon.href = null;
  }

  ngOnInit() {
    let urllink = window.location.href;
    if (urllink.toLowerCase().includes('gamesbox-ultimate')) {
      this.titleService.setTitle("Ultimate Games Box");
      this.changeIcon();
    }
  }

}
