import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ApiUrlService } from './shared/api-url.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ng2-cookies';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgProgressModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ApiUrlService, CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }